body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.search-label {
  width: 100%;
}
/* Formik css */
input {
  padding: 0.46rem;
  max-height: 38px;
  /* font-size: 16px; */
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input.error {
  border-color: #cc0000;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.input-feedback {
  color: #cc0000;
  margin-top: 0.25rem;
}

.error-div {
  color: #cc0000;
  margin-top: 0.5rem;
}

.action-button,
.action-button:hover,
.action-button:active,
.action-button:visited {
  text-decoration: none;
  color: #999;
  cursor: pointer;
}

.app-header {
  background-color: #20a8d8 !important;
  border-bottom: 1px solid #2593b9 !important;
}

.brand-text {
  color: #fff;
  margin-left: 20px;
}

.white-toggler {
  filter: brightness(2);
}
.white-toggler:hover {
  filter: brightness(4.2);
}
.white-nav-text {
  color: #fff;
}

.pill {
  padding: 4px 12px;
  color: #fff;
  font-size: 0.66rem;
  border-radius: 10px;
}
.red-pill {
  background-color: #ff4640;
}
.yellow-pill {
  background-color: #fff495;
  color: #333;
}

.purple-pill {
  background-color: #b616b6;
}
.green-pill {
  background-color: #1ae17d;
}
.orange-pill {
  background-color: #ff7b00;
}

.btn-info {
  color: #fff !important;
}

@media print {
  body {
    padding: 10px;
  }

  .error-div {
    border: 0;
    padding: 0;
    overflow: visible;
  }
  input {
    border: 0;
    padding: 0;
    overflow: visible;
  }
  .card {
    background-color: none !important;
    border: 0 !important;
  }
}

.DateRangePicker_picker {
  z-index: 10;
}

.packingList-button-area {
  margin-bottom: 10px;
  justify-content: center;
}

#observations {
  width: 100%;
  padding: 10px;
  resize: none;
}

#secondPrintButton {
  margin-left: 10px;
}

.sidebar {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
