.card-header-buttons {
  font-size: 12px;
  padding: 4px 6px;
  margin-left: 5px;
}

.card-header-button {
  font-size: 12px;
  padding: 4px 6px;
  margin-left: 10px;
}
.print-text {
  font-size: 18px;
}
.print-table-text {
  font-size: 15px;
  margin: 30px 0;
}

.small-text {
  font-size: 12px;
}
.small-header-padding {
  padding: 6px 12px;
}
.small-header-padding-pl {
  padding: 2px 12px;
  background-color: #20a7d838;
}
.small-header-padding-observation {
  padding: 6px 12px;
  background-color: rgba(0, 138, 0, 0.2);
}

.item {
  opacity: 1;
}

.dragged .item {
  opacity: 0.2;
}

.floating .item {
  background-color: #ffffff;
  box-shadow: 0 0.2rem 2rem #666666;
}

/* ******************** */

.card-header-observation {
  text-align: center;
  margin-left: 10px;
}

.card-header-created-at {
  text-align: center;
  margin-right: 20px;
  font-size: 12px;
}

.vertical .item {
  display: flex;
}

.vertical .item .icon {
  font-size: 2.5rem;
  margin-right: 1rem;
}

.vertical .item .details {
  flex-grow: 2;
}
